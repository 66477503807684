import React from 'react'
import { Modal, ModalContent, Header, Image } from 'semantic-ui-react'
import { Primary } from '../Buttons'
import sadFace from '../../svg/sadFace.svg'
import { useAuthContext } from '../../context/AuthContext'
import { usePrismic } from '../../context/PrismicContext'
import { Oops, Title, P } from './LoginErrorModal.styles'

const styles = {
  header: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  image: {
    height: '128px',
    width: '128px',
    margin: '1rem',
  },
  button: {
    width: '100%',
  },
}

const LoginErrorModal = ({ open, onCloseModal }) => {
  const { setQLoginIssue, qLoginIssue } = useAuthContext()

  const {
    prismicData: { prismicErrorData, prismicAutoshipPage },
  } = usePrismic()

  const onClose = () => {
    onCloseModal()
    setQLoginIssue('')
  }

  return (
    <Modal
      closeIcon
      style={{ width: '80%', maxWidth: 300 }}
      open={open}
      centered={true}
      closeOnDimmerClick={true}
      closeOnEscape
      onClose={onClose}
    >
      <Header style={styles.header} image>
        <Image src={sadFace} style={styles.image} fluid />
        <Oops>{prismicErrorData.oops}</Oops>
      </Header>
      <ModalContent>
        <Title>{qLoginIssue}</Title>
        <P>{`${prismicErrorData.email}: ${prismicErrorData.cs_email}`}</P>
        <Primary onClick={onClose} style={styles.button}>
          {prismicAutoshipPage.close.toUpperCase()}
        </Primary>
      </ModalContent>
    </Modal>
  )
}

export default LoginErrorModal
