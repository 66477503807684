import styled from 'styled-components'

export const Oops = styled.span`
  font-weight: 900;
  font-size: 36px;
  margin: 0.5rem;
`

export const Title = styled.h3`
  font-size: 18px;
  font-weight: 700;
  margin: 0.5 rem 0.5rem 1rem 0.5rem;
  text-align: center;
`

export const P = styled.p`
  text-align: center;
  font-size: 16px;
`
